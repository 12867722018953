import $ from 'jquery';
import { ScrollSpy } from 'bootstrap';
import { useEffect, useState } from 'react';
import logo from './images/ColossalSoft_Logo__Black.svg';
import navbrand from './images/ColossalSoft_Logo__LightBlue.png';
import wendyWebsite from './images/wendyWebsite.png';
import karhuDesigns from './images/karhu-designs.png';
import teamGreen from './images/teamGreen.png';
import kelleigh from './images/kelleigh.jpeg';
import reagan from './images/reagan.jpeg';

import '@fortawesome/fontawesome-pro/js/all.js';
import './App.scss';

const year = (new Date()).getFullYear();


function App() {
  const [showScrollFab, setShowScrollFab] = useState(false);

  useEffect(() => {
    var scrollSpyContentEl = document.getElementById('app');
    ScrollSpy.getOrCreateInstance(scrollSpyContentEl);
    scrollSpyContentEl.onscroll = onWindowScroll;    
  }, []);

  function onWindowScroll() {
    let isScrolledFarEnoughDown = document.getElementById('app').scrollTop > 20 || document.documentElement.scrollTop > 20;
    setShowScrollFab(isScrolledFarEnoughDown);
  }

  function scrollToTop() {
    $('#app').animate({
      scrollTop: 0
    }, 500);
  }

  return (
    <div>
      <button className={showScrollFab ? "btn btn-danger text-light btn-fab me-3 mb-3 me-lg-5 mb-lg-5" : "d-none"} onClick={scrollToTop}>
        <i class="fa-solid fa-chevrons-up"></i>        
      </button>

      <div id="app" className="App d-flex flex-column" data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="0">
        <nav id="navbar" className="navbar navbar-expand-lg fixed-top">
          <div className="container justify-content-start">
            
            <button className="navbar-toggler text-secondary no-underline" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fa-solid fa-bars"></i>
            </button>
            <a className="navbar-brand text-secondary d-lg-none" onClick={scrollToTop}>
              ColossalSoft, LLC
            </a>
            <a className="navbar-brand d-none d-lg-inline"  onClick={scrollToTop}>
              <img src={navbrand} alt="colossalsoft kraken logo" width="32" height="37" />
            </a>
            
            
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="nav">
                <li className="nav-item">
                  <a className="nav-link" href="#company-services">Services</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#company-testimonials">Testimonials &amp; Portfolio</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#company-contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div id="company-header" className="container ">
          <div className="wave-container">
            <div className="wave wave-1"></div>
            <div className="wave wave-2"></div>
            <div className="wave wave-3"></div>
          </div>
          <div className="row">
            <div className="col-6 offset-3">
              <div className="squid-and-bubbles">
                <div className="bubble pink x1"></div>
                <div className="bubble x2"></div>
                <div className="bubble x3"></div>
                <div className="bubble x4"></div>
                <div className="bubble pink x5"></div>

                <img src={logo} className="squid-logo" alt="logo" />

                <div className="bubble pink x6"></div>
                <div className="bubble pink x7"></div>
                <div className="bubble x8"></div>
                <div className="bubble x9"></div>
                <div className="bubble pink x10"></div>
              </div>
            </div>
          </div>
          <div className="row company-title">
            <h1>COLOSSALSOFT, LLC</h1>
            <h4>Software &amp; Business Solutions with a <i>Giant</i> Impact</h4>
          </div>
        </div>

        <div id="company-services" className="container-fluid section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto">
                <h3>Services</h3>
              </div>
              <div className="col-12">
                <p>We are a full-service shop, dedicated to implementing quality business and software solutions at the price-point our clients can afford.</p>
              </div>
              <div className="col-auto mt-3">
                <a className="btn btn-secondary" href="#company-contact" role="button">Feeling Inspired? Contact Us!</a>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div className="card bg-dark">
                  <div className="card-body">
                    <h5 className="card-title">DEVELOPMENT</h5>
                    <h6 className="card-subtitle my-2">Custom Software, Websites, Responsive Web Apps</h6>
                    <div className="card-text text-left mt-3">
                      <p>We write, maintain, and update your websites and software</p>
                      <ul>
                        <li>Custom website development</li>
                        <li>Website development using platforms like Wix, Google Sites, and WordPress</li>
                        <li>Custom APIs and API integrations with 3rd-party tools and payment processors like Stripe</li>
                        <li>Management of domain names, DNS records, databases, etc.</li>
                        <li>Use of modern technologies like React, Vue.js, Node.js</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div className="card bg-dark">
                  <div className="card-body">
                    <h5 className="card-title">DESIGN</h5>
                    <h6 className="card-subtitle my-2">Websites, Logos, Marketing Materials</h6>
                    <div className="card-text text-left mt-3">
                      <p>We work closely with clients to create designs that reflect their brands and personalities</p>
                      <ul>
                        <li>Website design</li>
                        <li>Logos and icon design</li>
                        <li>Business cards</li>
                        <li>Advertising materials, like yard signs, stickers, etc.</li>
                        <li>T-Shirt and other clothing designs</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div className="card bg-dark">
                  <div className="card-body">
                    <h5 className="card-title">GOOGLE</h5>
                    <h6 className="card-subtitle my-2">Business Listing, Analytics, Admin, Advertising</h6>
                    <div className="card-text text-left mt-3">
                      <p>We are experts at all things Google</p>
                      <ul>
                        <li>Google Account Admin</li>
                        <li>Set up emails with your business domain name (i.e. suport@colossalsoft.io)</li>
                        <li>Google Business Listing management</li>
                        <li>Set up website analytics tracking</li>
                        <li>SEO - Appear higher in search results</li>
                        <li>Set up advertising campaigns</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                <div className="card bg-dark">
                  <div className="card-body">
                    <h5 className="card-title">ANALYSIS</h5>
                    <h6 className="card-subtitle my-2">Areas for Improvement, Growth</h6>
                    <div className="card-text text-left mt-3">
                      <p>We can help analyze your current business and identify areas for growth</p>
                      <ul>
                        <li>Identify problem-areas</li>
                        <li>Brainstorm and implement solutions</li>
                        <li>Find areas for growth</li>
                        <li>Set up relationships</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="company-testimonials" className="container-fluid section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto">
                <h3>Portfolio &amp; Testimonials</h3>
              </div>
            </div>

            <div className="row mt-5 text-dark">
              <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                <div className="card bg-dark">
                  <img className="card-img-top" src={wendyWebsite} alt="wendy hurd website screenshot" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">Physical Therapist, Wendy Hurd</h5>
                    <h6 className="card-subtitle my-2">Custom React Website, SEO</h6>
                    <p className="card-text mt-3">
                      We worked closely with Wendy to build a custom React website that would allow her to accept appointments, list out services,
                      and showcase her extensive collection of journal publications.
                    </p>
                    <figure>
                      <blockquote class="blockquote fst-italic">
                        <p>
                         "Kelleigh did an amazing job with my website! She listened to my ideas and vision, and then made 
                         it happen. It's definitely made a difference in helping to grow my new business!"
                        </p>
                      </blockquote>
                      <figcaption class="blockquote-footer">
                        Wendy, P.T., PhD, SCS
                       </figcaption>
                    </figure>
                    <a href="http://wendy-hurd-demo-site.s3-website.us-east-2.amazonaws.com/" target="_blank" rel="noreferrer" className="btn btn-danger">Check It Out!</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                <div className="card bg-dark">
                  <img className="card-img-top" src={karhuDesigns} alt="t-shirt and sticker designs" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">CrossFit Jääkarhu</h5>
                    <h6 className="card-subtitle my-2">Custom T-Shirt and Merch Designs</h6>
                    <p className="card-text mt-3">
                      Members of Crossfit Jääkarhu love buying branded t-shirts and other merchandise.
                      We worked with the gym's coaches to revamp their online Shopify store and fill it with tons of fun
                      designs and apparel.
                    </p>
                    <figure>
                      <blockquote class="blockquote fst-italic">
                        <p>
                          "Kelleigh and Reagan are an absolute pleasure to work with! 
                          When we found ourselves in a bit of a business rut, they helped us brainstorm new ideas and 
                          execute them quickly and effectively. They are highly creative but also very analytical and 
                          organized (perfect for the different kinds of work we needed). Once they understood our goals 
                          they provided us with new visions for what we could do with our business. Reagan and Kelleigh 
                          designed new t-shirts, sandals, stickers, decals and water bottles and got them uploaded to 
                          our website. They then revamped our website to make it more visually appealing and more integrated 
                          with our other business software. I highly recommend working with them for creative inspiration, 
                          technical support or any kind of troubleshooting. They are excellent at what they do!"
                        </p>
                      </blockquote>
                      <figcaption class="blockquote-footer">
                        Michael, Owner &amp; Coach
                       </figcaption>
                    </figure>
                    <a href="https://shop.karhustrength.com/collections/all?sort_by=best-selling" target="_blank" rel="noreferrer" className="btn btn-danger">Check It Out!</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                <div className="card bg-dark">
                  <img className="card-img-top" src={teamGreen} alt="team green website screenshot" />
                  <div className="card-body">
                    <h5 className="card-title mb-3"> Team Green Landscape and Fence</h5>
                    <h6 className="card-subtitle my-2">Logo Rebrand, Updated Google Presence, Website</h6>
                    <p className="card-text mt-3">
                      The brothers who own Team Green are so busy providing excellent customer service
                      and beautiful landscapes to their customers that they hadn't had time to update their branding or website since
                      opening their business 12 years ago. We revamped their presence on Google, refreshed their brand
                      with new logos/fonts/colors, and a built them a brand new website on the Google Sites platform.
                    </p>
                    <figure>
                      <blockquote class="blockquote fst-italic">
                        <p>
                          "Quote about how awesome we are goes here"
                        </p>
                      </blockquote>
                      <figcaption class="blockquote-footer">
                        Mike &amp; Bill, Owners
                       </figcaption>
                    </figure>
                    <a href="https://teamgreenlandscape.com/" target="_blank" rel="noreferrer" className="btn btn-danger">Check It Out!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="company-contact" className="container-fluid section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto">
                <h3>Contact Us</h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 my-3">
                We are a husband-wife duo looking to grow our business, especially locally to the Spokane, WA area!
                Please email us to get started on your project! We'd love to hear from you!
              </div>
              <figure className="col-auto figure img-avatar">
                <img src={kelleigh} alt="kelleigh" />           
                <figcaption class="figure-caption">Kelleigh</figcaption>   
              </figure>
              <figure className="col-auto figure img-avatar">
                <img src={reagan} alt="reagan" />             
                <figcaption class="figure-caption">Reagan</figcaption> 
              </figure>
              <h5 className="col-12 mt-3"><a href="mailto:support@colossalsoft.io">support@colossalsoft.io</a></h5>
            </div>
            <div className="row mt-3 justify-content-center">
              <a className="btn btn-secondary col-auto mx-2" href="https://github.com/ColossalSoftware" target="_blank" rel="noreferrer" role="button">
                <i className="fa-brands fa-github"></i>&nbsp;
                GITHUB
              </a>
              {/* <a className="btn btn-secondary col-auto mx-2" href="https://www.facebook.com/colossalsoftware/" target="_blank" rel="noreferrer" role="button">
                <i className="fa-brands fa-facebook"></i>&nbsp;
                FACEBOOK
              </a> */}
            </div>
          </div>
        </div>


        <footer className="footer mt-auto py-3">
          <div className="container">
            <span className="">Copyright &copy; 2017 - {year} ColossalSoft, LLC.</span>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
